import React from "react"
import { Helmet } from "react-helmet"

import FullScreenMessage from "../components/molecules/FullScreenMessage"
import { DarkButton } from "../components/atoms/Button"

const NotFoundResponse = () => (
  <>
    <Helmet>
      <title>404 - Page Not Found</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <FullScreenMessage heading="404 - not found" msg="The page you're looking for doesn't exist.">
      <DarkButton link="/">Return home</DarkButton>
    </FullScreenMessage>
  </>
)

export default NotFoundResponse
