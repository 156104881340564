import React from "react"
import styled from "styled-components"

const fullScreenLayout = ({ children, className, heading, msg }) => (
  <div className={className}>
    <div className="msg-wrap">
      <h1 className="fullScreenMessage_heading">{heading}</h1>
      <p className="fullScreenMessage_msg">{msg}</p>
      {children}
    </div>
  </div>
)

const FullScreenMessage = styled(fullScreenLayout)`
  width: 100%;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.contrast};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  padding: 2rem 1rem;
  min-height: 400px;

  .fullScreenMessage_heading {
    text-transform: uppercase;
  }

  p {
    margin-bottom: 2rem;
  }
`

export default FullScreenMessage
